<template>
  <div class="index">
        <!-- 顶部：提示语 输入框 -->
        <div class="pageContent_top_content" :style="packUp?'margin-bottom: 1.5rem;':'margin-bottom:0.15rem'">
            <div class="pageContent_top" @click.stop="packUp=false" :style="packUp?'height: 1.61rem;':''">
                 <div>
                    <p class="top_p1">您好，Haitunxuancang</p>
                    <p class="top_p2">寻找您需要的舱位</p>
                </div>
                <!-- 输入框 -->
                <div class="top_serch" :style="packUp?'height:2.3rem':''" @click.stop="packUp=true">
                    <div class="serch_folding"  @click.stop="packUp=true" :style="packUp?'display:none':''">
                        <div>查询舱位</div>
                        <div class="serch_icon"><img src="../../web/img/h5_2.png"></div>
                    </div>
                    <!-- 展开的输入框 -->
                    <div class="serch_packUp" v-if="packUp">
                        <!-- 开始 -->
                        <div class="packUp_item">
                            <div class="packUp_icon">
                                <img src="../../web/img/h5_6.png">
                            </div>
                            <el-select  v-model="params.startSeaport" filterable clearable :filter-method="getFreightPort" @change="getFreightPort" @clear="params.startSeaport = ''" popper-class="input_class" size="small" placeholder="深圳">
                                <el-option
                                v-for="item in FreightPortList1"
                                :key="item.id"
                                :label="item.name"
                                :value="item.cname">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 到达 -->
                        <div class="packUp_item">
                            <div class="packUp_icon">
                                <img src="../../web/img/h5_7.png">
                            </div>
                                <el-select  v-model="params.targetSeaport" filterable clearable :filter-method="getFreightPort1" @change="getFreightPort1" @clear="params.targetSeaport = ''" popper-class="input_class" size="small" placeholder="LOS ANGELES,CA">
                                <el-option
                                v-for="item in FreightPortList3"
                                :key="item.id"
                                :label="item.name"
                                :value="item.cname">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 船司 -->
                       <div class="packUp_item">
                            <div class="packUp_icon">
                                <img src="../../web/img/h5_8.png">
                            </div>
                            <el-select  v-model="params.shipCompanyName" filterable clearable :filter-method="getFreightCompany" @change="getFreightCompany" @clear="params.shipCompanyName = ''" popper-class="input_class" size="small" placeholder="船司">
                                <el-option
                                v-for="item in companyList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 柜型&时间 -->
                        <div class="packUp_item_bottom">
                            <!-- 柜型 -->
                             <div class="packUp_item packUp_item2">
                                <div class="packUp_icon packUp_icon2 packUp_icon4">
                                    <img src="../../web/img/h5_9.png">
                                </div>
                               <el-select  v-model="params.cabinetShape" filterable clearable :filter-method="getFreightDefineEnums" @change="getFreightDefineEnums" @clear="params.cabinetShape = ''" popper-class="input_class" size="small" placeholder="柜型">
                                    <el-option
                                    v-for="item in getFreightDefineEnumsList.boxModes"
                                    :key="item.index"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <!-- 时间 -->
                             <div class="packUp_item packUp_item3">
                                <div class="packUp_icon packUp_icon3">
                                    <img src="../../web/img/h5_10.png">
                                </div>
                                    <el-date-picker
                                    v-model="value1"
                                    type="date"
                                    placeholder="选择日期"
                                    size="small"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    :editable="false">
                                    </el-date-picker>
                            </div>
                        </div>
                        <div class="packUp_button" @click.stop="serchFun">查询</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pageContent_bottom" @click="packUp=false">
             <!-- tags -->
            <div class="pageContent_tags" :style="packUp?'display:none':''">
                <div class="tags_item" v-for="(item,index) in histroyData" :key="index" @click="getHistory(item)">
                    <div>
                        {{item.startSeaport}} <i class="el-icon-close" @click.stop="closeFun(item.startSeaport)"></i>
                    </div>
                </div>
            </div>
            <!-- 内容：优选航线 更多商品 舱位 -->
            <div class="pageContent_shippingSpace">
                <!-- top -->
                <div class="shippingSpace_top">
                    <div>优选航线</div>
                    <div class="right_text" @click="$router.push('/web/serch?value=')">更多商品<i class="el-icon-arrow-right"></i></div>
                </div>
                <!-- 舱位 -->
                <el-row :loading="loading">
                    <div v-for="(item,index) in tableData" :key="index" @click="telShow=true" class="shippingSpace_item">
                        <!-- 左边 -->
                        <div class="item_left">
                            <div class="item_top">
                                <div>{{item.startPortName1}}</div>
                                <div class="item_top_right">
                                    <div class="item_text">{{item.voyageDay}}天</div>
                                    <div class="item_icon1"><img src="../../web/img/h5_1.png" alt=""></div>
                                </div>
                            </div>
                            <div class="item_bottom">
                                <div>
                                    <div class="item_icon2"><img src="../../web/img/h5_3.png" alt=""></div>
                                    <div>{{item.cabinetShape}}</div>
                                </div>
                                <div>
                                    <div class="item_icon3"><img src="../../web/img/h5_4.png" alt=""></div>
                                    <div>{{item.shipCompanyName}}</div>
                                </div>
                                <div>
                                    <div class="item_icon4"><img src="../../web/img/h5_5.png" alt=""></div>
                                    <div>{{item.weekcloseFile}}/{{item.weekSail}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 右边 -->
                        <div class="item_right">
                            <p>{{item.targetPortName}}</p>
                            <p>${{item.cabinetAmount}}</p>
                        </div>
                    </div>
                </el-row>
                
            </div>          
        </div> 
        <van-dialog
            v-model="telShow"
            title="服务热线"
            :message="`确认拨打0755-25100671服务热线吗？`"
            show-cancel-button
            @confirm="contact"
            >
        </van-dialog>
  </div>
</template>

<script>
let _ = require('lodash')
export default {
    data() {
        return {
            telShow: false, // 显示电话弹框
            loading: false,
            packUp:false,//控制展开的输入框
            value1:'', // 选择时间
            total: '',
            params: {
                cabinetShape: "",
                order: "",
                pageIndex: 1,
                pageSize: 20,
                sailTime: "",
                sailTimeEnd: "",
                sailTimeStart: "",
                shipCompanyName: "",
                status: 1
            },
            pickerOptions: {
                disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7; //禁止选择今天以前的时间
                },
            },
            seachInount:0,
            histroyData:[], // 历史搜索
            tableData: [], // 首页数据
            FreightPortList1: [], // 出发地列表数据
            FreightPortList3: [], // 到达地列表数据
            companyList: [], // 船司列表数据
            getFreightDefineEnumsList: {}, // 柜型列表数据
        };
    },
    created(){
        this.getData();
        this.getFreightPort();
        this.getFreightPort1();
        this.getFreightCompany();
        this.getFreightDefineEnums()

    },
    mounted(){
        if( localStorage.getItem('histroyData')){
            this.histroyData = this.unique(JSON.parse(localStorage.getItem('histroyData')))
        }
    },
    methods:{
        // 打电话
        contact() {
            const a = document.createElement("a");
            a.href = "tel:0755-25100671"
            a.click();
            this.show = false;
        },
        unique(arr) {
             for (var i = 0; i < arr.length - 1; i++) {
                for (var j = i + 1; j < arr.length; j++) {
                    if (arr[i].startSeaport == arr[j].startSeaport && arr[i].targetSeaport == arr[j].targetSeaport) {
                        arr.splice(j, 1);
                        //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
                        j--;
                    }
                }
            }
            return arr  
             console.log(arr,'  console.log(arr) ')
        } ,
        // 出发地数据列表
        getFreightPort(val){
            this.params.startSeaport = val
            this.$post('/system/getFreightPort',{name:val}).then(res =>{
                this.FreightPortList1 = res.data.list
                console.log(this.params.startSeaport)
                if(!val){
                    this.FreightPortList1.unshift({
                        cname:'深圳',
                        name:'SHENZHEN',
                        id:999999
                    })
                }
            })
        },
        // 到达地数据列表
         getFreightPort1(val){
            this.params.targetSeaport = val
            this.$post('/system/getFreightPort',{type:'target',name:val}).then(res =>{
                this.FreightPortList3 = res.data.list
            })
        },
        // 船司数据列表
         getFreightCompany(val){
            this.params.shipCompanyName = val
            this.$post('/system/getFreightCompany',{name:val}).then(res =>{
                this.companyList = res.data.list
            })
        },
        // 柜型数据列表
        getFreightDefineEnums(val){
            this.params.cabinetShape = val
            this.$get('/system/getFreightDefineEnums').then(res =>{
                this.getFreightDefineEnumsList = res.data
            })
        },
        // 首页数据
        getData(){
            this.loading = true
             if(this.value1 != null && this.value1.length != 0){
                this.params.sailTimeStart = this.value1[0] + ' 00:00:00'
                this.params.sailTimeEnd = this.value1[1] + ' 23:59:59'
            } else {
                this.params.sailTimeStart = ''
                this.params.sailTimeEnd = ''
            }
            this.$post('/freight/getPreferredFreight',this.params).then(res =>{
                this.total = res.totalCount
                this.loading = false
                res.data.list.forEach(ele => {
                    
                    let data1 = new Date(ele.sailTime).getDay()
                    let data2 = new Date(ele.closePassTime).getDay()
                    if(data1 == 0){
                        ele.weekSail = 7
                    } else {
                        ele.weekSail = data1
                    }
                    if(data2 == 0){
                        ele.weekcloseFile = 7
                    } else {
                        ele.weekcloseFile = data2
                    }
                    if(ele.surchargeAmountDetail){
                       if(ele.surchargeAmountDetail != "[]" && ele.surchargeAmountDetail != "[\u0000]"){
                            ele.surchargeAmountDetail = JSON.parse(ele.surchargeAmountDetail)
                        let usdTotal = 0
                        let cnyTotal = 0
                        ele.surchargeAmountDetail.forEach(ele1=>{
                            if(ele1.feeType == 'CNY'){
                                cnyTotal += ele1.fee
                            }
                            if(ele1.feeType == 'USD'){
                              usdTotal += ele1.fee
                            }
                            ele.usdTotal = usdTotal
                            ele.cnyTotal = cnyTotal
                        })
                       } else {
                           ele.surchargeAmount = 0
                           delete ele.surchargeAmountDetail
                       }
                    }
                    if(ele.supplyBizUserName) {
                        ele.mingzi =  ele.supplyBizUserName.substr(0,1) + "*"
                    } else {
                    }
                    if(ele.supplyBizUserPhone){
                         ele.tuomin = this.randomPhoneNumber()
                    } else {
                        ele.tuomin = this.randomPhoneNumber()
                    }
                    if(ele.remark == '备注:'){
                        delete ele.remark
                    }
                    if(ele.depositAmount == 0){
                        ele.depositAmount = '--'
                    } else if(!ele.depositAmount){
                         ele.depositAmount = '--'
                    }
                    ele.startPortName1=  this.getParenthesesStr(ele.startPortName)
                    ele.compositeGrade = 5
                    ele.closeFileTime = ele.closeFileTime.substr(0, 10)
                    ele.closePassTime = ele.closePassTime.substr(0, 10)
                    ele.sailTime = ele.sailTime.substr(0, 10)
                });
                if(res.data.length != 0){
                    this.tableData = this.tableData.concat(res.data.list)
                }
            }).catch(err =>{
                this.loading = false
            })
        },
         getParenthesesStr(text) {
            let result = ''
            let regex = /\((.+?)\)/g;
            let options = text.match(regex)
            let option = options[0]
            result = option.substring(1, option.length - 1)
            return result
        },
          // 随机生成电话号码
        randomPhoneNumber () {
            let firstThree = [151, 152, 199, 138, 150, 182, 168, 135, 136, 158, 159, 177, 153, 154, 155, 157, 131, 132, 137, 188]
            let lastFour = []
            let flag = Math.floor(Math.random() * 20)
            for (let i = 0; i < 4; i++) {
                lastFour.push(Math.floor(Math.random() * 10))
            }
            let number = `${firstThree[flag]}****${lastFour.join('')}`
            let txt = ''
            txt = number
            return txt
        },
        closeFun(val){
            if(val){
                let histroyData = JSON.parse(JSON.stringify(this.histroyData))
                for(let i =0 ;i<histroyData.length; i++){
                    let item = histroyData[i];
                    if(item.startSeaport == val){
                        this.histroyData.splice(i,1)
                    }
                }
                localStorage.setItem('histroyData',JSON.stringify(this.histroyData))
            }
        },
        // 查询
        serchFun: _.throttle(function () {
            let histroyData = localStorage.getItem('histroyData')
           
            if( this.params.startSeaport == '' || this.params.startSeaport == undefined){
                 this.params.startSeaport = '深圳'
                this.$set(this.params,'startSeaport','深圳')
            }
            if( this.params.targetSeaport == '' || this.params.targetSeaport == undefined){
              
                this.params.targetSeaport = 'LOS ANGELES,CA'
                  this.$set(this.params,'targetSeaport','LOS ANGELES,CA')
            }
             if(this.params.startSeaport && this.params.targetSeaport){
                if (!histroyData){
                    histroyData = []
                } else {
                    histroyData = JSON.parse(histroyData)
                }
                let show = true;
                for(let i =0 ;i<histroyData.length; i++){
                    let item = histroyData[i];
                    if(item.startSeaport == this.params.startSeaport){
                        show = false;
                    }
                }
                if(show){
                    histroyData.unshift({
                        startSeaport:this.params.startSeaport,
                        targetSeaport:this.params.targetSeaport
                    })
                }
                localStorage.setItem('histroyData',JSON.stringify(histroyData))
            }
            // debugger
            // return false
            this.seachInount = 1
            this.params.pageIndex = 1
            //  this.getData()
              if(this.value1 != null && this.value1.length != 0){
                this.params.sailTime = this.value1 + ' 00:00:00'
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
             if(this.value1 != null && this.value1.length != 0){
                this.params.sailTime = this.value1 + ' 00:00:00'
            }
             this.$router.push('/web/serch?value=' + JSON.stringify(this.params))
            let params = this.params
            params.userId =  localStorage.getItem("empId")
            let userInfo = localStorage.getItem('userInfo')
            if(userInfo !=null){
                 params.mobileNum  = JSON.parse(userInfo).user.phone
            }
            if(this.params.startSeaport == undefined){
                this.params.startSeaport = ''
            }
             if(this.params.targetSeaport == undefined){
                this.params.targetSeaport = ''
            }
            // this.$post('/index/click?sailTime=' + this.params.sailTime + '&=' + '&startSeaport=' + this.params.startSeaport + '&targetSeaport=' + this.params.targetSeaport + '&companyName='+ this.params.shipCompanyName + '&cabinetShape=' + this.params.cabinetShape + '&mobileNum=' + params.mobileNum + '&userId=' + this.params.userId,this.params).then(res =>{})
        }, 3000),
        // 历史查询按钮
        getHistory: _.throttle(function (data) {
            this.params.startSeaport = data.startSeaport
            this.params.targetSeaport =  data.targetSeaport
            this.seachInount = 1
            this.params.pageIndex = 1
            if(this.value1 != null && this.value1.length != 0){
                this.params.sailTime = this.value1 + ' 00:00:00'
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
             if(this.value1 != null && this.value1.length != 0){
                this.params.sailTime = this.value1 + ' 00:00:00'
            }
            this.$router.push('/web/serch?value=' + JSON.stringify(this.params) )
            let params = this.params
            params.userId =  localStorage.getItem("empId")
            let userInfo = localStorage.getItem('userInfo')
            if(userInfo !=null){
                 params.mobileNum  = JSON.parse(userInfo).user.phone
            }
            if(this.params.startSeaport == undefined){
                this.params.startSeaport = ''
            }
             if(this.params.targetSeaport == undefined){
                this.params.targetSeaport = ''
            }
            // this.$post('/index/click?sailTime=' + this.params.sailTime + '&=' + '&startSeaport=' + this.params.startSeaport + '&targetSeaport=' + this.params.targetSeaport + '&companyName='+ this.params.shipCompanyName + '&cabinetShape=' + this.params.cabinetShape + '&mobileNum=' + params.mobileNum + '&userId=' + this.params.userId,this.params).then(res =>{})
         }, 3000),
    }
}
</script>

<style lang="scss" scoped>
.index{
    background: #F5F5F5;
    box-sizing: border-box;
}

.pageContent_top{
    width: 100%;
    height: 1.03rem;
    background: linear-gradient(92deg, #73DAFF 0%, #1677FF 68%);
    opacity: 1;
    padding: 0.13rem 0.26rem;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    box-sizing: border-box;
    
}

.top_serch{
    width: 3.23rem;
    height: 0.44rem;
    box-shadow: 0 0.04rem 0.26rem 0.01rem rgba(0,0,0,0.25);
    border-radius: 0.09rem;
    box-sizing: border-box;
    background: #fff;
}

.serch_folding{
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 0.15rem 0.15rem 0.15rem 0.11rem;
    font-size: 0.14rem;
    color: #ACACAC;
    border-radius: 0.09rem;
    letter-spacing: 0.03rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.serch_icon{
    width: 0.15rem;
    height: 0.15rem;
}

.serch_icon img{
    width: 100%;
    height: 100%;
}

.packUp_item{
    border-bottom: 0.01rem solid #C4C4C4;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 0.03rem;
}
.packUp_item:last-child{
    margin-bottom: 0;
}

.packUp_item_bottom{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.11rem;
}

.packUp_button{
    width: 2.47rem;
    height: 0.44rem;
    background: #1677FF;
    border-radius: 0.09rem;
    line-height: 0.44rem;
    text-align: center;
    color: #fff;
    font-size: 0.16rem;
    margin: 0 auto;
     box-shadow: 0 0.04rem 0.26rem 0.01rem rgba(0,0,0,0.25);
}

.packUp_item2{
    flex: 0 0 35%;
    margin-bottom: 0;
}

.packUp_item3{
    flex: 1;
    margin-left: 0.29rem;
    margin-bottom: 0;

}

.serch_packUp{
    width: 100%;
    height: 100%;
    padding: 0.23rem 0.3rem;
    box-sizing: border-box;
}

.packUp_text{
    font-size: 0.13rem;
    color: #ACACAC ;
}

.packUp_icon{
    width: 0.21rem;
    height: 0.22rem;
    margin-top: 0.06rem;
    flex: 0 0 8%;
}

.packUp_icon img{
    width: 100%;
    height: 100%;
}

.packUp_icon2{
    flex: 0 0 24%;
}

.packUp_icon3{
    flex: 0 0 16%;
}

.packUp_icon4{
    margin-top: 0.08rem;
}

.packUp_right_icon{
    width: 0.11rem;
    height: 0.07rem;
    margin-top: 0.02rem;
}

.packUp_right_icon img{
    width: 100%;
    height: 100%;
}

.pageContent_bottom{
    padding: 0.12rem 0.26rem 0.26rem 0.26rem;
    box-sizing: border-box;
}

.top_p1{
    font-size: 0.14rem;
    padding-bottom: 0.1rem;
    color: #fff;
    letter-spacing: 0.01rem;
    box-sizing: border-box;
}

.top_p2{
    font-size: 0.18rem;
    padding-bottom: 0.2rem;
    color: #fff;
    letter-spacing: 0.03rem;
    box-sizing: border-box;
}

.pageContent_tags{
    overflow: hidden;
    box-sizing: border-box;
}

.tags_item{
    height: 0.22rem;
    border-radius: 0.09rem 0.09rem 0.09rem 0.09rem;
    opacity: 1;
    border: 0.01rem solid #ACACAC;
    box-sizing: border-box;
    padding: 0.05rem 0.08rem 0.04rem 0.12rem;
    float: left;
    margin-right: 0.07rem;
    margin-bottom: 0.1rem;
}

.tags_item div:nth-child(1){
    margin-right: 0.04rem;
    white-space:nowrap;
    font-size: 0.11rem;
    color: #ACACAC;
}

.pageContent_shippingSpace{
    color: #464646;
}

.shippingSpace_top{
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    margin-bottom: 0.13rem;
}

.right_text{
    font-size: 0.12rem;
    color: #1677FF;
    margin-top: 0.01rem;
}

.shippingSpace_item{
    background: #fff;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    height: 0.67rem;
    background: #FFFFFF;
    border-radius: 0.09rem; 
    opacity: 1;
    padding: 0.12rem 0.16rem;
    margin-bottom: 0.04rem;
}

.item_left{
    flex: 0 0 35%;
    min-width: 1.45rem;
}

.item_right{
    text-align: right;
    font-size: 0.12rem;
}

.item_right p:last-child{
    font-size: 0.16rem;
    color: #FF6B00;
    padding-top: 0.14rem;
    box-sizing: border-box;
}

.item_top{
    display: flex;
    justify-content: space-between;
    font-size: 0.12rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
}

.item_top_right{
    margin-right: 0.14rem;
}

.item_text{
    font-size: 0.08rem;
    margin-left: 0.02rem;
}

.item_icon1{
    width: 0.35rem;
    height: 0.05rem;
    margin-top: -0.05rem;
}

.item_icon1 img{
    width: 100%;
    height: 100%;
}

.item_icon2{
    width: 0.11rem;
    height: 0.08rem;
    margin-right: 0.02rem;
}

.item_icon2 img{
    width: 100%;
    height: 100%;
}

.item_icon3{
    width: 0.09rem;
    height: 0.09rem;
    margin-right: 0.02rem;
}

.item_icon3 img{
    width: 100%;
    height: 100%;
}

.item_icon4{
    width: 0.08rem;
    height: 0.08rem;
    margin-top: 0.01rem;
    margin-right: 0.02rem;
}

.item_icon4 img{
    width: 100%;
    height: 100%;
}

.item_bottom{
    display: flex;
    font-size: 0.08rem;
    white-space: nowrap;
}

.item_bottom>div{
    display: flex;
    margin-right: 0.05rem;
}

.item_bottom>div:last-child{
    margin-right: 0;
}

</style> 

<style lang="scss">
.packUp_item3 .el-date-editor .el-input__prefix{
    display: none;
}
.packUp_item3 .el-input--prefix .el-input__inner {
    border: none;
    padding-left: 0;
}

.packUp_item3 .el-date-editor{
    width: 100%;
}

.packUp_item .el-select{
    width: 100%;
}

.packUp_item .el-select .el-input__inner{
    border: none;
    padding: 0 0.3rem 0 0;
}

.packUp_text .el-input__inner{
    width: 100% !important;
    border: none !important;
    padding: 0 0.3rem 0 0;
    background: none;
}
</style>